var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$apollo.loading)?_c('li',[(_vm.$hasPrivileges(
        _vm.$privileges('CAN_PUBLISH_ITEMS'),
        _vm.isSysAdmin,
        _vm.privilegesForItem,
      ) && _vm.showPublishPageButton && !_vm.disableButtonsOnAutosave)?_c('b-button',{staticClass:"mr-2 green-button-link",on:{"click":_vm.publishPage}},[_c('b-icon',{staticClass:"top--1 mr-1",attrs:{"icon":"cloud-upload-fill","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('buttons.publishPage'))+" ")],1):_vm._e(),(_vm.$hasPrivileges(
        _vm.$privileges('CAN_PUBLISH_ITEMS'),
        _vm.isSysAdmin,
        _vm.privilegesForItem,
      ) && _vm.showPublishChangesButton && !_vm.disableButtonsOnAutosave)?_c('b-button',{staticClass:"mr-2 green-button-link",on:{"click":_vm.publishPage}},[_c('b-icon',{staticClass:"top--1 mr-1",attrs:{"icon":"cloud-upload-fill","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('buttons.publishChanges'))+" ")],1):_vm._e(),_c('b-button',{staticClass:"mr-4 transparent-white-button",attrs:{"data-page-step":"5"},on:{"click":_vm.previewPage}},[_c('b-icon',{staticClass:"top--1 mr-1",attrs:{"icon":"eye","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('buttons.preview'))+" ")],1),(_vm.isPublished)?_c('b-button',{staticClass:"dark-grey-buttons mr-2",on:{"click":_vm.showUrlModal}},[_c('b-icon',{staticClass:"top--2",attrs:{"id":"header-tooltip-eye","icon":"link","aria-hidden":"true"}})],1):_vm._e(),(!_vm.isPublished)?_c('b-tooltip',{attrs:{"target":"header-tooltip-eye-changed"}},[_c('small',[_vm._v(_vm._s(_vm.$t('webmag.publishProjectChangesFirst')))])]):_vm._e(),(_vm.isPublished)?_c('b-tooltip',{attrs:{"target":"header-tooltip-eye"}},[_c('small',[_vm._v(_vm._s(_vm.$t('webmag.showItemUrl')))])]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }